window.lang = {
  menu_home: "Home",
  Send: "Send",
  "Please enter your 6 digit pin": "Please enter your 6 digit pin",
  menu_currencies: "Currencies",
  menu_vault: "Vault",
  menu_apex: "Apex",
  menu_history: "History",
  menu_help: "Help",
  menu_faq: "Faq",
  menu_logout: "Logout",
  Login: "Login",
  Password: "Password",
  "Bank account": "Bank account",
  "Please contact our customer service to add another bank account.":
    "Please contact our customer service to add another bank account.",
  "Asia Branch": "Asia Branch",
  "Tel : (+60) 345 34 678": "Tel : (+60) 345 34 678",
  "Fax : (+60) 345 34 679": "Fax : (+60) 345 34 679",
  "Europe Branch": "Europe Branch",
  "Main Branch": "Main Branch",
  "Mena Branch": "Mena Branch",
  Capture: "Capture",
  "'Select your currency and the amount you want to capture.'":
    "'Select your currency and the amount you want to capture.'",
  "Use an EZY number to capture your gold.":
    "Use an EZY number to capture your gold.",
  Currencies: "Currencies",
  Cancel: "Cancel",
  Confirm: "Confirm",
  Amount: "Amount",
  Fee: "Fee",
  "Amount converted": "Amount converted",
  Rate: "Rate",
  "Live chat": "Live chat",
  "Our chat working hours are listed in EST below:":
    "Our chat working hours are listed in EST below:",
  "Mon: 2:00 AM to midnight": "Mon: 2:00 AM to midnight",
  "Tue-Thu: 24-hours": "Tue-Thu: 24-hours",
  "Fri: midnight to 6:00": "Fri: midnight to 6:00",
  "Your message has been sent successfully.":
    "Your message has been sent successfully.",
  "Commission list": "Commission list",
  Convert: "Convert",
  "You are converting from %s to %s.": "You are converting from %s to %s.",
  Balance: "Balance",
  "Destination amount": "Destination amount",
  "Your session has expired": "Your session has expired",
  Close: "Close",
  Information: "Information",
  "Your session will expire in less than one minute. Click on 'Refresh' in order to refresh your session.":
    "Your session will expire in less than one minute. Click on 'Refresh' in order to refresh your session.",
  Warning: "Warning",
  Refresh: "Refresh",
  "You do not have any data.": "You do not have any data.",
  "See all": "See all",
  Captures: "Captures",
  "Send email": "Send email",
  Chatting: "Chatting",
  "Call us": "Call us",
  "Latest captures": "Latest captures",
  "Choose your security image": "Choose your security image",
  Choose: "Choose",
  Principal: "Principal",
  Commission: "Commission",
  Error: "Error",
  Info: "Info",
  Back: "Back",
  "h.text": "text",
  "Something went wrong. Please try again later":
    "Something went wrong. Please try again later",
  "Oops! We detected an error error. Please check your connection and try again.":
    "Oops! We detected an error error. Please check your connection and try again.",
  Reload: "Reload",
};
